<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            type="year"
            defaultStart="-2y"
            defaultEnd="0y"
            label="(기간)년도"
            name="periodYear"
            v-model="searchParam.periodYear"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="저장시설 허가량 목록"
      tableId="chemprod-inout"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template>
          <span>
            <template v-if="props.row.col3 < 80">
              {{props.row.col3}}  
            </template>
            <q-badge v-else color="negative" :label="props.row.col3" class="blinking" />
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'store-danger-permit',
  data() {
    return {
      searchParam: {
        plantCd: null,
        periodYear: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '저장시설',
            align: 'left',
            style: 'width:200px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'col4',
            field: 'col4',
            label: '저장시설 위치',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '총 허가량',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost'
          },
          {
            name: 'col3',
            field: 'col3',
            label: '허가량 대비 재고량 비율(%)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'custom',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [];
      let max = this.$comm.generateRandom(10, 20);
      for (let i = 1; i <= max; i++) {
        this.grid.data.push({ plantName: '사업장1', col1: '탱크' + i, col4: '위치' + i, col2: this.$comm.generateRandomComma(800, 1300) + 'kg', col3: this.$comm.generateRandom(40, 85), useYn: 'Y' },)
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '저장시설 허가량'; // 저장시설 허가량
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'./storeDangerPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // this.getList();
    },
  }
};
</script>
